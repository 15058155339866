import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { parseGraphResultToArrayNoEdges } from '@src/utils/parsers';

import { ArticlesSection } from '../articles-section/ArticlesSection';

import * as styles from './thank-you-section.module.scss';

export const ThankYouNews = () => {
  const theLatestNewsQueryResult = useStaticQuery(graphql`
    query latestNewsInArray {
      allMarkdownRemark(
        filter: { frontmatter: { layout: { eq: "news" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        nodes {
          frontmatter {
            layout
            title
            path
            date
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  `);

  const latestNewsInArray = useMemo(() => parseGraphResultToArrayNoEdges(theLatestNewsQueryResult), []);

  return (
    <ArticlesSection
      title="News"
      articles={latestNewsInArray.slice(0, 3)}
      type="news"
      classNames={{
        customContainer: styles.customContainer,
        innerWrapper: styles.innerWrapper,
      }}
    />
  );
};
