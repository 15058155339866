import React, { useMemo } from 'react';

import { getPostDate } from '@src/utils/calculations';

import { Link } from '@commons/link/Link';
import { Button } from '@commons/button/Button';

import newsImage from '@images/thank-you/news.svg';

import * as styles from './news-post-box.module.scss';

export const NewsPostBox = ({ path, title, date, thumbnail }) => {
  const prevPostDate = useMemo(() => getPostDate(date), []);
  return (
    <div className={styles.container} style={{ background: `url(${thumbnail.publicURL}) no-repeat center/cover` }}>
      <Link href={path} className={styles.box}>
        <span className={styles.header}>
          <div className={styles.headerImage}>
            <img loading="lazy" src={newsImage} alt="news" />
          </div>
          <p>{prevPostDate}</p>
        </span>
        <span className={styles.title}>{title}</span>
        <Button label="Read more" className={styles.button} href={path} />
      </Link>
    </div>
  );
};
