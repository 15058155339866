import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import { ThankYouUnderHeader, ThankYouBlog, ThankYouNews, ThankYouHeader } from '@pages-impl/thank-you';

export default function Index(props) {
  return (
    <Layout contactFormType={false} {...props}>
      <SEO title="Thank you - CodiLime" noIndex={true} />
      <ThankYouHeader />
      <ThankYouUnderHeader />
      <ThankYouBlog />
      <ThankYouNews />
    </Layout>
  );
}
