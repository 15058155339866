import React from 'react';

import { NewStandardSection } from '@src/components/commons';
import Link from 'gatsby-link';
import { paths } from '@src/constants/pathsEnum';

import image from './images/image.svg';
import arrow from './images/arrow.svg';

import * as styles from './thank-you-under-header.module.scss';

export const ThankYouUnderHeader = () => {
  return (
    <NewStandardSection classNames={{ outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper }}>
      <div className={styles.leftPart}>
        <img className={styles.triangle} src={image} alt="Free CodiLime knowledge" />
      </div>
      <div className={styles.rightPart}>
        <h2 className={styles.title}>Free knowledge at your fingertips</h2>
        <span className={styles.subtitle}>All our resources in one place</span>
        <Link className={styles.link} to={paths.RESOURCES.BASE}>
          <button className={styles.button}>
            Explore <img loading="lazy" src={arrow} alt="arrow" />
          </button>
        </Link>
      </div>
    </NewStandardSection>
  );
};
