import React from 'react';

import { NewStandardSection } from '@src/components/commons';

import backgroundImage from './images/thank-you-background.svg';

import * as styles from './thank-you-header.module.scss';

export const ThankYouHeader = () => {
  return (
    <header className={styles.header}>
      <div className={styles.backgroundImageContainer}>
        <img loading="lazy" src={backgroundImage} alt="Thank you for your message!" />
      </div>
      <NewStandardSection
        classNames={{ section: styles.section, outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper }}
      >
        <p className={styles.message}>Thank you for your message!</p>
      </NewStandardSection>
    </header>
  );
};
