import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { parseGraphBlogPostsToArray } from '@src/utils/parsers';

import { ArticlesSection } from '../articles-section/ArticlesSection';

import * as styles from './thank-you-blog.module.scss';

export const ThankYouBlog = () => {
  const theLatestBlogQueryResult = useStaticQuery(graphql`
    query ThankYouBlogSection {
      allMarkdownRemark(
        filter: { frontmatter: { layout: { eq: "blog" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              authors {
                frontmatter {
                  surname
                  name
                  path
                  avatar {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                    extension
                    publicURL
                  }
                }
              }
              shortDescription
              title
              path
              date
              thumbnail {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                extension
                publicURL
              }
              blogKeywords
              postCategories {
                frontmatter {
                  postCategoryName
                }
              }
            }
            wordCount {
              words
            }
          }
        }
      }
    }
  `);
  theLatestBlogQueryResult;
  const latestBlogInArray = useMemo(() => parseGraphBlogPostsToArray(theLatestBlogQueryResult.allMarkdownRemark), []);

  return (
    <ArticlesSection
      title="Blog"
      articles={latestBlogInArray.slice(0, 3)}
      type="blog"
      classNames={{
        customContainer: styles.customContainer,
        innerWrapper: styles.innerWrapper,
      }}
    />
  );
};
