import React from 'react';
import cx from 'classnames';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import { BlogCard } from '@commons/blog-post-card/BlogCard';
import { NewsPostBox } from '@commons/news-post-box/NewsPostBox';

import * as styles from './articles-section.module.scss';

export const ArticlesSection = ({ title, type, articles = [], classNames: { customContainer, innerWrapper } = {} }) => {
  const ArticleComponent = type === 'blog' ? BlogCard : NewsPostBox;

  return (
    <NewStandardSection
      title={title}
      classNames={{
        innerWrapper: cx(styles.innerWrapper, innerWrapper),
        titlesContainer: styles.titlesContainer,
        titleStyles: styles.titleStyles,
      }}
    >
      <div className={cx(styles.container, customContainer)}>
        {articles.map((data, i) => (
          <ArticleComponent key={i} {...data} />
        ))}
      </div>
    </NewStandardSection>
  );
};
